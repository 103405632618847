.kr-icon-arrow {
  display: block;
  position: absolute;
  right: 42px;
  top: 7px;
  transition: transform 0.1s ease-in;
  z-index: 2;
  cursor: pointer;

  svg {
    width: 10px;
    height: 18px;

    path {
      fill: rgb(113, 113, 113);
    }
  }
}

.kr-icon-inner-wrapper {
  position: relative;

  .kr-icon-wrapper-layer {
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
  }
}

.kr-brand-selector {
  background-color: #fff;
  box-shadow: 0 3px 6px rgb(0, 0, 0, 16%), 0 3px 6px rgb(0, 0, 0, 23%);
  opacity: 0;
  padding: 8px;
  position: absolute;
  right: -7px;
  top: 28px;
  z-index: 5;
  margin-top: 10px;
  border: 1px solid #aaa;

  .kr-brand-selector-wrapper {
    position: relative;
  }

  .kr-arrow {
    border: 1px solid #aaa;
    display: block;
    position: absolute;
    top: -15px;
    left: 16px;
    width: 11px;
    height: 11px;
    transform: rotate(45deg);
    background-color: #fff;
    border-bottom-color: rgb(255, 255, 255, 0%);
    border-right-color: rgb(255, 255, 255, 0%);
  }

  ul {
    padding: 0;
    margin: 0;
    text-align: center;
  }

  li {
    background-color: #fff;
    cursor: pointer;
    display: block;
    height: 30px;
    list-style-type: none;
    margin: 5px 0 0;
    padding: 0;

    svg {
      box-sizing: content-box;
      border: 4px solid rgb(0, 0, 0, 0%);
      cursor: pointer;
    }
  }

  li.kr-selected-brand {
    svg {
      border: 4px solid #b0bec5;
    }
  }

  svg {
    width: 34px;
    height: 22px;
  }
}

.kr-open {
  .kr-brand-selector {
    opacity: 1;
  }
}

/* Firefox */
&.kr-firefox .kr-brand-selector .kr-arrow {
  top: 0;
}
