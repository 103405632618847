@use "../../../mixins/smartform/card-tooltip";
@use "../skeleton";
@use "../variables" as *;

$animation-name: "kr-card-icon-display";
$skeleton-bg: linear-gradient(
  100deg,
  rgba(255, 255, 255, 0%) 30%,
  rgba(255, 255, 255, 70%) 50%,
  rgba(255, 255, 255, 0%) 70%
);

@mixin keyframes() {
  @keyframes #{$animation-name} {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

@mixin main($padding-left: 0, $location: button) {
  .kr-card-icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    min-width: 0;
    width: 100%;
    padding-left: $padding-left;
    cursor: pointer;

    @include tooltip;
    @include brands(span, $location);
  }
}

@mixin tooltip() {
  span.kr-extra-brands {
    height: 22px;
    width: 30px;
    cursor: pointer;
    color: #777;
    font-family: Roboto, sans-serif;
    margin-left: 2px;
    font-size: 13px;
    max-width: 16px;

    @include card-tooltip.main($padding: 10px, $border-radius: 0);
  }
}

@mixin brands($container-element: span, $location: button) {
  @if $container-element == none {
    $container-element: "";
  }
  #{$container-element}.kr-brand-icon {
    height: 22px;
    width: 30px;
    margin-right: 6px;
    cursor: pointer;

    @include placeholder;

    &:not(.kr-cards).kr-loading-assets {
      display: flex;
      justify-content: center;
    }

    svg {
      padding: 2px 4px;
      height: 18px;
      width: 22px;
      border-radius: 0;
      animation: icon-display 0.2s ease-in;
    }

    // Amex
    &.kr-amex svg {
      background-color: #006fce;
    }

    // Cabal
    &.kr-cabal,
    &.kr-cabal_debit {
      svg {
        padding: 0;
        width: 30px;
        height: 22px;
        background-color: #026095;
      }
    }

    // CB
    &.kr-cb {
      padding: 0;
      height: 22px;
      width: 33px;

      svg {
        padding: 0;
        height: 22px;
        width: initial;
      }
    }

    // Kadeos
    &.kr-kadeos_culture,
    &.kr-kadeos_culture svg,
    &.kr-kadeos_gift,
    &.kr-kadeos_gift svg {
      padding: 0;
      height: 22px;
      width: 35px;

      image {
        cursor: pointer;
      }
    }

    // Naranja
    &.kr-naranja svg {
      box-sizing: border-box;
      width: 30px;
      height: 22px;
      padding: 3px 4px;
    }

    // Visa
    &.kr-visa svg,
    &.kr-visa_electron svg,
    &.kr-visa_debit svg {
      padding: 0 2px;
      height: 22px;
      width: 26px;
    }

    // Assets loading
    &.kr-loading-assets {
      height: 20px;
      width: 30px;

      svg {
        padding: 0;
        height: 20px;
        width: 30px;
      }
    }
  }

  #{$container-element}.kr-brand-icon.kr-cards {
    box-shadow: $icons-box-shadow;

    @include skeleton.animation;

    &.kr-loading-assets {
      @if $location == button {
        background: $skeleton-bg $color-light-secondary;
      } @else {
        background:
          100deg,
          rgba(244, 244, 244, 0%) 30%,
          rgba(244, 244, 244, 70%) 50%,
          rgba(244, 244, 244, 0%) 70% $color-light;
      }

      animation: 1s skeleton-animation ease-in-out infinite;
      background-size: 200% 100%;
      background-position-x: 180%;
      transition: all 0.3s;

      svg {
        visibility: hidden;
      }
    }
  }
}

@mixin placeholder() {
  .kr-placeholder {
    display: inline-flex;
    box-shadow: $icons-box-shadow;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: $skeleton-bg $color-light-secondary;
    animation: 1s skeleton-animation ease-in-out infinite;
    background-size: 200% 100%;
    background-position-x: 180%;
    transition: all 0.3s;
  }
}
