#portal .kr-embedded-wrapper-isVisible .kr-embedded {
  width: auto;

  .kr-pan,
  .kr-field.kr-text,
  .kr-field.kr-checkbox,
  .kr-expiry,
  .kr-security-code,
  .kr-identity-document-number,
  .kr-card-holder-name,
  .kr-card-holder-mail,
  .kr-identity-document-type,
  .kr-installment-number,
  .kr-first-installment-delay,
  & > .kr-field-wrapper {
    margin-top: 14px;
    margin-left: 10px;
    width: $inner-width;
  }

  .kr-payment-button,
  .kr-form-error {
    margin-left: 10px;
    width: $inner-width;
  }
}

// table&co
.payzen #embedded-cart-tpl #krtoolbar-birdplace #embedded-form.kr-embedded,
.page-template-default.mega-menu-primary #embedded-cart-tpl #user-wrapper-form #embedded-form.kr-embedded {
  width: auto;

  #kr-form {
    .mdl-cell.mdl-cell--4-col {
      margin: 8px;
      padding: 0 6px;
    }

    .mdl-cell.mdl-cell--4-col .kr-pan,
    .mdl-cell.mdl-cell--4-col .kr-expiry,
    .mdl-cell.mdl-cell--4-col .kr-security-code {
      max-width: 232px;
    }

    .column.is-4 .kr-pan,
    .column.is-4 .kr-expiry,
    .column.is-4 .kr-security-code {
      height: auto;
    }
  }
}
