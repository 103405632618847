@use "./smartform/split-payment";
@import "./variables";

@mixin button() {
  box-sizing: border-box;
  margin-bottom: 7px;
  width: 100%;
  border: 0;
  background-color: $color-primary;
  color: $color-light;
  padding: 12px 0;
  font-size: 1em;
  outline: none !important;
  height: 46px;
  font-family: inherit;
  cursor: pointer;
  transition: box-shadow 0.4s;

  &:focus {
    background-color: $color-primary;
    color: $color-light;
    font-family: Roboto, sans-serif;
    box-shadow: rgb(0, 0, 0, 16%) 0 3px 6px, rgb(0, 0, 0, 23%) 0 3px 6px;
  }
}

.kr-payment-button {
  @include button;

  span {
    user-select: none;
    display: inline-block;
    width: 100%;
    line-height: 22px;
    color: inherit;
    text-align: center;
    font-family: Roboto, sans-serif;
    cursor: pointer;
  }

  // Loading state
  &.kr-loading {
    display: block;

    span {
      display: none;
    }

    .kr-spinner {
      display: block;
    }
  }

  &:disabled span {
    cursor: not-allowed;
  }

  @include split-payment.button-label;
}
