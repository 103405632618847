@use "../../fonts";

$padding-label: 2px 0;
$margin-error: 0;

@mixin spacing($gap) {
  gap: $gap;
}

// Section label
@mixin label() {
  &__label {
    padding: $padding-label;

    @include fonts.default;
  }
}

// Error
@mixin error() {
  .kr-form-error {
    margin: $margin-error;
  }
}
