@use "sass:math";

// Fixing for Old browsers
&[krvue] {
  .kr-pan,
  .kr-field.kr-text,
  .kr-field.kr-checkbox,
  .kr-expiry,
  .kr-security-code,
  .kr-identity-document-number,
  .kr-card-holder-name,
  .kr-card-holder-mail,
  & > .kr-field-wrapper {
    .kr-field-component {
      width: $field-width - $icon-width - 2px;
    }

    .kr-field-component .kr-iframe-wrapper iframe {
      height: 33px !important;
    }

    &.kr-size-half .kr-field-component {
      width: math.div($field-width, 2) - $icon-width - 5px;
    }
  }
}
