@use "./../loading-overlay";
@use "./wallet";
@use "./methods-list";
@use "../../classic-popin/header" as popin-header;
@use "./grid";
@use "./card-header";
@use "./header";
@use "./partial-payment-panel";
@use "./split-payment";

.kr-smart-form-wrapper.kr-type-embedded {
  @include card-header.main;
  @include methods-list.embedded;
  @include popin-header.smartform;
  @import "./errors";

  width: 100%;
}

@import "./skeleton";
@import "./extras_form";
@include wallet.main;
@include loading-overlay.main;
@include header.main;

// Grid mode
&[kr-grid] {
  @include grid.main;
}

@include partial-payment-panel.main;
@include split-payment.smartform;
