@use "../variables" as *;

@mixin popin() {
  &.kr-wallet {
    padding-top: 90px + 60px + 5px;
  }

  .kr-wallet-tabs {
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
  }
}

@mixin embedded() {
  .kr-wallet-tabs {
    margin-bottom: 20px;

    .kr-wallet-tab {
      padding: 5px 10px;
      margin: 0 4px;
      border-bottom: 2px solid rgb(0, 0, 0, 0%);
      cursor: pointer;

      &.kr-selected {
        background-color: rgb(0, 0, 0, 0%);
        border-bottom: 2px solid $color-primary;
      }

      span {
        font-family: Roboto, sans-serif;
        font-size: 16px;
        color: $color-primary;
        cursor: pointer;
      }

      &.kr-disabled span {
        color: $color-grey-font;
      }
    }
  }
}
