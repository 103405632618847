.kr-identity-document-number.kr-error,
.kr-card-holder-name.kr-error,
.kr-card-holder-mail.kr-error {
  // Error
  .kr-field-wrapper.kr-text-wrapper {
    .kr-icon-wrapper-root svg path {
      fill: #d9534f;
    }
  }
}
