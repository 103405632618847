$animation-name: "kr-bouncedelay";

@mixin keyframes() {
  @keyframes #{$animation-name} {
    0%,
    80%,
    100% {
      transform: scale(0);
    }

    40% {
      transform: scale(1);
    }
  }
}

@mixin main() {
  .kr-spinner {
    box-sizing: border-box;
    display: none;
    margin: 0 auto;
    text-align: center;
    padding-top: 1px;
    height: 22px;
    width: 70px;

    > div {
      width: 18px;
      height: 18px;
      background-color: #cfcfcf;
      border-radius: 100%;
      display: inline-block;
      animation: #{$animation-name} 1.4s infinite ease-in-out both;
    }

    .kr-bounce1 {
      animation-delay: -0.32s;
    }

    .kr-bounce2 {
      animation-delay: -0.16s;
    }

    @include -frozen;
  }
}

@mixin -frozen() {
  &.kr-button-animation--frozen {
    > div {
      animation: none;
    }
  }
}
