@use "sass:math";

// Font
$font-family: roboto, sans-serif;
$font-size: 14px;
$color-font: #444;

// Card fields
$field-width: 231px;
$icon-width: 44px;
$help-width: 50px;
$form-width: $field-width + $help-width;
$inner-width: $field-width + $help-width - 15px;

// SmartForm
$smartform-width: 300px;
$smartform-grid-width: 350px;
$smartform-header-height: 68px;
$smartform-radio-button-size: 20px;
$smartform-compact-width: 425px;
$smartbutton-width: 300px;
$smartform-methods-gap: 10px;
$smartform-section-margin: 10px;
$smartform-border-color: #b7b7b7;

// Smarform list options
$smartform-option-height: 50px;
$font-color-disabled: #999;
$border-color-disabled: #ddd;
$icons-box-shadow: rgb(0, 0, 0, 7%) 0 6px 24px 0, rgb(0, 0, 0, 24%) 0 0 0 1px;

// Cards
$card-elements-gap: 15px;

// Single payment button
$smartform-spb-width: 340px;
$smartform-spb-radio-border-color: #e3e3e3;

// SmartForm popin
$smartform-popin-width: 330px;
$smartform-popin-border: 5px;
$smartform-popin-border-radius: 0;
$smartform-popin-margin-x: 15px;
$smartform-popin-padding: 100px 0 70px 0;
$smartform-grid-popin-padding: 100px 0 70px 0;
$smartform-popin-box-shadow: rgb(0, 0, 0, 30%) 0 19px 38px,
  rgb(0, 0, 0, 22%) 0 15px 12px;

// SmartForm Popin content
$smarform-popin-cardform-width: 300px;
$smartform-popin-content-padding: 15px 0 0 0;

// SmartButton
$smartbutton-spb-width: $smartform-spb-width;

// Colors
$color-light: #fff;
$color-grey: #464a4c;
$color-disabled: #ebebe4;
$color-grey-font: #717171;
$color-grey-icons: $color-grey-font;
$color-grey-border: #b1b1b1;
$color-grey-placeholder: #999;
$color-icon-error: #d9534f;
$color-warning: #f9a825;
$color-error: #b71c1c;
$color-primary: var(--kr-global-color-primary, #293c7a);
$color-primary-light: #3952a5;
$color-secondary: #d97706;
$color-light-secondary: #f4f4f4;
$circle-size: 30px;
$check-height: math.div($circle-size, 2);
$check-width: math.div($check-height, 2);
$check-left: math.div($circle-size, 6);
$check-thickness: math.div($circle-size, 10);
$button-width: 232px;
$button-height: 46px;
$shop-name-width: 245px;
$breakpoint-popin: 1px 450px;
$sides-margin: 50px;
$top-margin: 16px;
$bottom-margin: 30px;
