.kr-outer-wrapper {
  box-sizing: border-box;
  background-color: $color-light;
  display: block;
  width: $field-width - $icon-width;
  height: 31px;

  .kr-inner-wrapper {
    box-sizing: border-box;
    background-color: $color-light;
    display: block;
    padding: 3px 4px 4px 8px;
    zoom: 1;
    width: $field-width - $icon-width;
    height: 31px;
  }
}

.kr-field-wrapper {
  .kr-icon .kr-icon-wrapper {
    box-sizing: border-box;
    display: inline-block;
    padding: 0;
    margin: 0;
  }

  .kr-icon-image {
    box-sizing: border-box;
    display: inline-block;
  }
}

.kr-installments-wrapper {
  margin: 14px 0 16px 10px !important;
  display: block;
}

.kr-installments-wrapper,
.kr-installments-wrapper select {
  width: 232px;
  height: 32px;
}

.kr-installments-wrapper select {
  padding-left: 5px;
}
