@use "sass:color";
@use "../../../mixins/smartform/radio-button";
@use "../../../mixins/smartform/button";
@use "../variables" as *;
@use "../wallet";
@use "./card-icons";

@mixin delete-button-hover() {
  &.kr-smart-form-wallet-card--hover-delete {
    border-color: $color-error;

    &.kr-method-label--spbtn {
      border-color: $color-error;
    }

    &.kr-method-label--selected {
      background-color: color.scale($color-error, $lightness: 90%);
      border-color: $color-error;
    }

    @include radio-button.main(
      $size: $smartform-radio-button-size,
      $border-color: #e3e3e3,
      $border-color-hover: $color-error,
      $active-color: $color-error
    );

    .kr-delete-button-wrapper {
      .kr-delete-button .kr-delete-icon svg path {
        fill: $color-error;
      }
    }
  }
}

@mixin card() {
  .kr-smart-form-wallet-card {
    display: flex;

    &.kr-smart-form-wallet-card--alert {
      @include button.alert-button-animation;
    }

    @include card-icons.brands($container-element: none);
    @include wallet.delete-button-embedded;
    @include delete-button-hover;

    .kr-brand-icon {
      margin-right: 12px;
    }

    label {
      width: 0;
      flex-grow: 1;
    }

    .kr-delete-button-wrapper {
      &.kr-card-list {
        height: 100%;
        padding: 0 21px;
      }
    }

    .kr-loading-animation {
      margin-right: 20px;
    }
  }
}

@mixin label() {
  &.kr-smart-form-wallet-card {
    padding-right: 0;
  }
}

@mixin create-token() {
  &.kr-delete-button--create-token {
    .kr-delete-button-wrapper {
      .kr-delete-button .kr-delete-icon svg path {
        fill: $color-error;
      }
    }
  }

  &.kr-method-label--create-token-selected {
    border-color: $color-error;
    background-color: #f9e9e9;
  }
}

@mixin main() {
  .kr-smart-form-wallet {
    display: block;

    @include card;
  }

  /**
   * Quick'n'dirty fix, to be removed with https://jira.portal.lyra-network.com/browse/KJS-3360
   */
  .kr-smart-form-modal .kr-methods-list .kr-methods-list-options:first-child .kr-smart-form-wallet:first-child,
  .kr-smart-form-embedded-header + .kr-methods-list .kr-methods-list-options .kr-smart-form-wallet:first-child {
    padding-top: 10px;
  }
}
