.kr-layer {
  .kr-popin-modal-header {
    border-bottom: 0;
    background-color: rgb(0, 0, 0, 0%);
  }

  .kr-popin-logo-layer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    background-color: rgb(30, 30, 30, 80%);
  }
}
