.kr-field.kr-text {
  input.kr-theme {
    padding-top: 6px !important;
  }
}

/* &[krvue] {
  .kr-expiry {
    &.kr-size-half .kr-field-component iframe {
      width: $field-width/2 - $icon-width + 1px !important;
    }
  }

  .kr-security-code {
    &.kr-size-half .kr-field-component iframe {
      width: $field-width/2 - $icon-width + 7px !important;
    }
  }

  .kr-security-code.kr-size-half[kr-order='2'] {
    width: calc(50% - 7px) !important;
  }
} */
