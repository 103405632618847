@import "../base/custom_checkbox";
@import "../base/custom_radio";

div[id^="kr-field-app-"] {
  width: 100%;
}

// Hosted fields themed customization
input.kr-theme {
  display: block;

  &[type="text"] {
    height: 31px;
  }

  &[type="checkbox"] {
    height: 46px;
  }
}

// Text
.kr-field.kr-text {
  position: relative;

  &.kr-field-wrapper.kr-visible {
    display: flex;
  }

  .kr-input-wrapper {
    @include order(1);

    width: 100%;
    background-color: $color-light;
  }

  .kr-icon {
    @include order(2);

    i {
      display: inline-block;
      color: #717171;
      font-size: 1.5em;
    }

    &.kr-error i {
      color: #d9534f;
    }
  }

  input.kr-theme {
    box-sizing: border-box;
    border: none;
    font-size: 0.9em;
    margin-top: 0;
    position: static;
    line-height: 1em;
    background-clip: unset;
    margin-right: 9px;
    padding-left: 10px;
    font-family: Roboto, sans-serif;
    color: #717171;
    padding-top: 2px !important;
    padding-bottom: 0 !important;
    outline: none;
    visibility: visible;
    cursor: text !important;

    @include placeholder {
      color: #999;
      opacity: 1;
    }
  }
}

> input.kr-theme {
  display: none;
  width: $field-width - $icon-width;
  height: 33px;
  background-color: $color-light;
  padding-left: $icon-width;
}

// Checkbox
// Label
.kr-checkbox-input-wrapper {
  .kr-field-control,
  .kr-field-control .kr-checkbox-label {
    font-family: Roboto, sans-serif;
  }

  .kr-checkbox input.kr-field.kr-theme {
    visibility: visible;
    display: inline-block;
    margin-right: 0;
  }
}

// Default type
.kr-field.kr-checkbox.kr-checkbox-type-switch {
  .kr-field-wrapper {
    height: auto;

    .kr-checkbox-input-wrapper {
      .kr-field-control.kr-checkbox.kr-checkbox-layout {
        padding-left: 0 !important;
        display: flex;
        align-items: center;
      }

      // Unchecked
      .kr-checkbox-control-indicator {
        top: 0;
        background-color: #e6e6e6;
      }

      // Hover when checked
      .kr-field-control:hover input:not([disabled]):checked ~ .kr-checkbox-control-indicator {
        background-color: #717171;
      }
    }
  }
}

// Switch type
.kr-checkbox-input-wrapper .kr-field-control {
  // Label
  .kr-checkbox-label {
    color: #777;
  }

  // Default background
  input.kr-theme {
    background: #ddd;

    // Checked background
    &:checked {
      background: #000;
    }

    // Button
    &::after {
      background: #fff;
    }
  }
}

.kr-field.kr-radio {
  display: flex;
  align-items: center;
  flex-direction: row;

  label {
    outline: none;
    font-size: 0.9em;
    line-height: 1em;
    font-family: Roboto, sans-serif;
    color: #717171;
  }
}
