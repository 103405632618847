@use "./variables" as *;

@mixin main() {
  .kr-currency-conversion {
    padding-top: 4px;

    span {
      color: $color-grey-font;
      font-family: Roboto, sans-serif;
      line-height: 20px;
      font-size: 13px;

      strong {
        color: $color-primary;
        font-family: Roboto, sans-serif;
        font-size: 13px;
      }
    }
  }
}
