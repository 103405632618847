@use "../classic/wallet";
@use "./header";
@use "./footer";
@use "./smartform/modal-button";

/* Components */
@import "./button";

kr-smart-form[kr-popin] {
  @import "./layer";
}

.kr-embedded[kr-popin] {
  display: none;
  box-shadow: 0 0 15px #121212;
  transition: height 1s;
  width: $form-width + $sides-margin;
  margin: 0;

  @include header.main;
  @import "./field";
  @import "./wrappers";
  @import "./error";
  @import "./payment_button";
  @import "./help-button";
  @import "./layer";
  @include wallet.delete-button-popin;
  @include wallet.card-list-popin;
  @include wallet.tabs-popin;
  @include footer.legacy;
}

.kr-popin-wrapper {
  @media (max-width: 450px) {
    padding-top: 45px;
  }

  &--large-form {
    padding: 45px 0;
  }
}

// Popin utils
@include modal-button.legacy;
