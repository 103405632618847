$default-sides-margin: 30px;

// Hidden
&.kr-help-button-hidden {
  width: $field-width + $default-sides-margin * 2;
  padding-left: $default-sides-margin;
  padding-right: $default-sides-margin;

  .kr-popin-modal-footer {
    .kr-whitelabel-logo img {
      margin-right: $default-sides-margin;
    }
  }
}

// Inner (default position)
&.kr-help-button-inner-field {
  width: $field-width + $sides-margin * 2 - 20px;
  padding-left: $sides-margin - 20px;
  padding-right: $sides-margin - 20px;

  .kr-pan,
  .kr-field.kr-text,
  .kr-field.kr-checkbox {
    width: 100%;

    .kr-help-button-wrapper.kr-inner.kr-visible .kr-help-button {
      width: $help-width - 12px;
    }
  }

  .kr-security-code.kr-size-half {
    .kr-help-button-wrapper.kr-inner .kr-help-button {
      width: $help-width - 13px;
      height: 30px;
    }
  }

  .kr-payment-button,
  .kr-form-error {
    width: 100%;
    margin-right: 0;
  }

  .kr-popin-modal-footer {
    .kr-whitelabel-logo img {
      margin-right: $sides-margin - 20px;
    }
  }
}
