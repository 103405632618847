@use "./mixins/smartform/card-tooltip";
@use "./package/no-theme/smartform/loading-animation";
@use "./package/classic/smartform/card-icons";
@use "package/classic/discount-panel";
@use "package/classic/spinner";
@use "package/classic/wallet";
@use "package/classic/layer";
@use "package/classic/modal";
@use "package/classic/popin-redirection";
@use "package/classic/layer-manager";
@use "package/classic/currency-conversion";
@use "package/classic/smartform/card-header";
@use "package/classic/smartform/split-payment";
@use "package/classic/root-variables";
@import "package/classic/variables";
@import "breakpoint_definition";
@include loading-animation.keyframes;
@include spinner.keyframes;
@include card-icons.keyframes;
@include modal.keyframes;
@include layer.keyframes;

/* Font */
@import "https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap";

/* Mixins */
@import "mixins/breakpoint";
@import "mixins/flex";
@import "desktop/mixin/clearfix";
@import "desktop/mixin/aspect_ratio";
@import "desktop/mixin/vertical_align";
@import "desktop/mixin/words";
@import "desktop/mixin/form_fields";
@import "desktop/mixin/placeholder";

/* Vendors */
@import "animations/base";
@import "animations/flippers/flipInX";

/* Popin */
@import "package/classic-popin/index";

/* Discount Panel */
@include discount-panel.root;

/* Split Payment Panel */
@include split-payment.root;

/* Brand buttons */
@import "package/classic/brand-button";

/* Skeleton */
@import "package/classic/skeleton";

.kr-embedded,
.kr-smart-form {
  @import "package/classic/custom_field";
  @import "package/classic/error";
  @import "package/classic/button";
  @import "package/classic/help-button";
  @include spinner.main;
}

/* Smart Form */
.kr-smart-form {
  z-index: 1;
  width: $smartform-width;

  &[kr-single-payment-button] {
    width: $smartform-spb-width;
  }

  &[kr-grid] {
    width: $smartform-grid-width;
  }

  &.kr-smart-form--compact {
    width: $smartform-compact-width;
  }

  @import "package/classic-popin/smartform/index";
  @import "package/classic/smartform/index";
  @import "package/classic/smartform/smart_button";

  /**
   * See KJS-1882 | Necessary to increase selector weight in SmartForm case.
   *   Without it, all: initial rule in _initial.scss will revert width
   *   to .kr-smart-form's ($smartform-width)
   */
  .kr-embedded,
  .kr-embedded .kr-payment-button,
  .kr-embedded .kr-form-error {
    width: 100%;
  }
}

@include layer-manager.main;
@import "package/classic/smartform/smart_button";

/* Default (embedded) */
.kr-embedded {
  width: $inner-width;
  font-family: Roboto, sans-serif;

  &.kr-outside-smartform {
    width: $smartform-width;
  }

  // Flex direction
  @include flex-direction(row);

  /* Components */
  @import "package/classic/brand-selector";
  @import "package/classic/field";
  @import "package/classic/select";
  @import "package/classic/wrappers";
  @import "package/classic/icon";
  @import "package/classic/disabled";
  @import "package/classic/text-field";
  @include wallet.delete-button-embedded;
  @include wallet.card-list-embedded;
  @include wallet.tabs-embedded;
  @import "package/classic/visibility_button";
  @import "package/classic/layer";
  @include card-header.main;
  @include currency-conversion.main;

  &.kr-ios,
  &.kr-macos {
    @import "package/classic/ios";
  }

  &.kr-ios-9 {
    @import "package/classic/old-browser";
  }

  @include discount-panel.embedded;
}

// 3DS Popin
@include popin-redirection.main;

/* PATCH */
@import "package/classic/legacy";
