@use "sass:math";

$select-height: 35px;

// Default select (mobile)
.kr-field-wrapper.kr-select-wrapper.kr-real-select {
  position: relative;

  select {
    box-sizing: border-box;
    border: none;
    line-height: $select-height - 2px;
    padding-left: 9px;
    font-family: Roboto, sans-serif;
    color: $color-grey-font;
    font-size: 14px;
  }

  span.kr-select-caret {
    position: absolute;
    right: 18px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    svg {
      fill: $color-grey-font;
    }

    &.kr-read-only svg {
      fill: $color-grey-placeholder;
    }
  }

  span.kr-select-placeholder {
    position: absolute;
    left: 9px;
    top: 0;
    height: 100%;
    right: 40px;
    line-height: 33px;
    font-family: Roboto, sans-serif;
    color: $color-grey-placeholder;
    font-size: 14px;
    pointer-events: none;
  }
}

// Custom select (desktop)
.kr-field-wrapper.kr-select-wrapper.kr-custom-select {
  .kr-select {
    box-sizing: border-box;

    @include flexbox;
    @include flex-direction(column);
    @include justify-content(center);

    border: none;
    width: 100%;
    height: $select-height;
    padding-left: 9px;

    span.kr-selected-option {
      font-family: Roboto, sans-serif;
      color: $color-grey-font;
      font-size: 14px;
      width: calc(100% - 25px);
    }

    span.kr-selected-option.kr-placeholder {
      color: $color-grey-placeholder;
    }

    .kr-select-caret {
      box-sizing: border-box;

      @include flexbox;
      @include flex-direction(column);
      @include justify-content(center);

      right: 16px;
      top: 0;
      height: $select-height - 1px;

      svg {
        fill: $color-grey-font;
      }

      &.kr-read-only svg {
        fill: $color-grey-placeholder;
      }
    }
  }

  .kr-options {
    width: 100%;
    left: -1px;
    max-height: $select-height * 6;
    overflow-x: hidden;
    overflow-y: auto;
    border-left: 1px solid $color-grey-border;
    border-right: 1px solid $color-grey-border;
    border-bottom: 1px solid $color-grey-border;
    z-index: 10000;

    .kr-option {
      box-sizing: border-box;

      @include flexbox;
      @include flex-direction(column);
      @include justify-content(center);

      height: $select-height;
      padding-left: 9px;
      font-family: Roboto, sans-serif;
      color: $color-grey-font;
      font-size: 14px;

      &:hover {
        background-color: #ddd;
      }
    }

    &.top {
      &.kr-options-6 {
        top: -$select-height * 6;
      }

      &.kr-options-5 {
        top: -$select-height * 5;
      }

      &.kr-options-4 {
        top: -$select-height * 4;
      }

      &.kr-options-3 {
        top: -$select-height * 3;
      }

      &.kr-options-2 {
        top: -$select-height * 2;
      }

      &.kr-options-1 {
        top: -$select-height * 1;
      }
    }

    &.bottom {
      top: $select-height - 2px;
    }

    &:not(:hover) {
      .kr-option.kr-active-option {
        background-color: #ddd;
      }
    }
  }
}

// Error
.kr-identity-document-type,
.kr-installment-number,
.kr-first-installment-delay {
  &.kr-error {
    .kr-field-wrapper.kr-select-wrapper.kr-custom-select,
    .kr-field-wrapper.kr-select-wrapper.kr-real-select {
      .kr-select-caret svg {
        fill: #d9534f;
      }
    }
  }
}
