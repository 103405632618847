$button-size: 22px;
$icon-size: 10px;
$button-margin: 16px;
$small-button-size: 18px;
$small-icon-size: 8px;

// Positions
.kr-field-wrapper .kr-help-button-wrapper {
  display: block;
  position: relative;
  height: 100%;

  .kr-help-button {
    display: block;
    width: $help-width;
    height: 35px;

    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    @include flex-direction(row);
  }

  .kr-help-button .kr-help-icon-wrapper {
    width: $button-size;
    height: $button-size;
    border-radius: 50%;
    background-color: #fff;

    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    @include flex-direction(row);

    box-shadow: 0 0 0 1px #717171;
    cursor: pointer;

    .kr-help-icon,
    .kr-custom-help-icon {
      @include flexbox;
      @include flex-direction(column);
      @include align-items(center);

      cursor: pointer;
    }

    .kr-help-icon svg {
      width: $icon-size;
      height: $icon-size;
    }

    .kr-help-icon svg path {
      fill: #717171;
    }

    .kr-custom-help-icon svg {
      width: 20px;
      height: 20px;
      background-color: #fff;
    }
  }
}

// Inner field (default)
&[kr-help-button="inner-field"]:not([krvue]) {
  .kr-pan,
  .kr-field.kr-text,
  .kr-field.kr-checkbox,
  .kr-expiry,
  .kr-security-code {
    width: $inner-width - 2px;
  }

  .kr-payment-button {
    width: $inner-width;
  }
}

&.kr-help-button-inner-field {
  // Icon with multibrand
  .kr-icon-wrapper-root.kr-multi-brand,
  .kr-icon-wrapper-root.kr-multi-brand .kr-icon {
    width: 39px;

    .kr-icon-arrow {
      right: -5px;
    }

    .kr-icon-wrapper .kr-icon-inner-wrapper .kr-icon-wrapper-content {
      @include justify-content(start);
    }

    .kr-brand-selector {
      right: -3px;
    }
  }

  .kr-help-button-wrapper.kr-inner {
    &:not(.kr-visible) {
      display: flex;
      width: 0;

      .kr-help-button {
        width: 0;
        opacity: 0;
      }
    }

    &.kr-visible {
      .kr-help-button {
        opacity: 1;
        width: $help-width - 11px;
        height: 31px;

        @include flexbox;
      }
    }

    .kr-help-button .kr-help-icon-wrapper {
      width: $small-button-size;
      height: $small-button-size;
      background-color: #717171;
      box-shadow: none;
    }

    .kr-help-button .kr-help-icon-wrapper.kr-error {
      background-color: $color-icon-error;
    }

    .kr-help-button .kr-help-icon-wrapper .kr-help-icon svg {
      width: $small-icon-size;
      height: $small-icon-size;
    }

    .kr-help-button .kr-help-icon-wrapper .kr-help-icon svg path {
      fill: #fff;
    }
  }

  // Pan help button animation
  .kr-pan {
    .kr-help-button-wrapper.kr-inner:not(.kr-visible) .kr-help-button {
      transition: width 0.5s;
    }

    .kr-help-button-wrapper.kr-inner.kr-visible .kr-help-button {
      transition: all 0.5s;
    }
  }

  .kr-security-code {
    .kr-icon-wrapper-root {
      display: none !important;
    }

    .kr-help-button-wrapper.kr-inner {
      .kr-help-button {
        width: $help-width - 7px;
      }
    }
  }
}
