@use "../../classic/spinner";
@use "../../classic/variables" as *;

@mixin legacy() {
  .kr-popin-button {
    @include -base;
  }
}

@mixin smartform() {
  .kr-smart-form-modal-button {
    @include -base;
  }
}

@mixin -base() {
  box-sizing: border-box;
  background-color: $color-primary;
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 0;
  outline: none !important;
  cursor: pointer;
  margin: 5px 0;

  &:disabled {
    cursor: not-allowed;
  }

  span {
    font-family: Roboto, sans-serif;
    font-size: 15px;
    color: #fff;
    cursor: pointer;
    user-select: none;
  }

  // Loading state
  &.kr-loading {
    display: block;

    span {
      display: none;
    }

    .kr-spinner {
      display: block;
    }
  }

  @include spinner.main;
}
