@use "../header";
@use "../footer";
@use "./modal_back_button";
@use "../../classic/smartform/methods-list";

.kr-smart-form-modal-wrapper {
  .kr-smart-form-modal {
    box-shadow:
      rgb(0, 0, 0, 30%) 0 19px 38px,
      rgb(0, 0, 0, 22%) 0 15px 12px;
    padding-top: 106px;
    padding-bottom: 70px;

    @include header.smartform;
    @include methods-list.popin;
    @include methods-list.popin-one-scroll;
    @include modal_back_button.popin;
    @include modal_back_button.popin-iframe;
    @include footer.smartform;

    &.kr-secure-iframe-visible {
      padding-top: 0;
      padding-bottom: 0;
    }

    .kr-embedded,
    .kr-embedded .kr-payment-button,
    .kr-embedded .kr-form-error {
      width: $inner-width;
    }

    &.kr-smart-form-modal--scrollable-content {
      max-height: 100%;
    }
  }
}
