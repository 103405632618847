@mixin placeholder {
  /*  stylelint-disable-next-line selector-pseudo-element-no-unknown */
  &::input-placeholder {
    @content;
  }

  &:placeholder { /* Firefox 18- */
    @content;
  }

  &::placeholder {  /* Firefox 19+ */
    @content;
  }

  /*  stylelint-disable-next-line selector-pseudo-class-no-unknown */
  &:input-placeholder {
    @content;
  }
}
