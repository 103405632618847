.kr-brand-buttons,
.kr-embedded .kr-brand-buttons {
  margin-bottom: 20px;
  min-height: 40px;

  .kr-brand-button .kr-brand-button-icon {
    margin: 0 5px;
    height: 40px;
    width: 47px;

    svg {
      opacity: 0.5;
    }
  }

  .kr-brand-button .kr-brand-button-label {
    font-family: Roboto, sans-serif;
    color: $color-grey-placeholder;
    font-size: 13px;
    height: 15px;
  }

  .kr-brand-button.kr-selected {
    svg {
      opacity: 1;
    }

    .kr-brand-button-label {
      color: $color-grey-font;
    }
  }
}

.kr-brand-buttons {
  width: $inner-width;
}
