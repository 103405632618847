@use "../../variables" as *;
@use "./section";
@use "./option";
@use "./back-button";
@use "../card-icons";
@use "../../fonts";
@use "../../../../mixins/smartform/grid/wallet";
@use "../../../../mixins/smartform/grid/method-option";

$option-items-spacing: 15px;
$icon-wrapper-width: 60px;
$button-padding-x: 10px;

@mixin section() {
  .kr-sf-opm {
    @include section.spacing(
      $gap: $smartform-methods-gap
    );
    @include section.label;
    @include section.error;

    &__methods {
      @include group;
    }
  }
}

@mixin group() {
  .kr-opm-group,
  .kr-grid-group {
    gap: $smartform-methods-gap;
  }

  @include option-btn;
}

@mixin option-btn() {
  .kr-method-btn {
    @include option.base($option-height: $smartform-option-height);

    // Inside button elements
    @include method-option.radio-btn(
      $size: $smartform-radio-button-size,
      $border-color: $smartform-spb-radio-border-color,
      $border-color-hover: $color-primary,
      $active-color: $color-primary,
      $margin: $option-items-spacing
    );
    @include option-icon;
    @include option-label;
    @include method-option.loading-animation($color-primary);

    // Variants
    @include method-option.hover($border-color: $color-primary);
    @include method-option.disabled(
      $border-color: $border-color-disabled,
      $font-color: $font-color-disabled
    );
    @include method-option.no-label;

    // Back button
    @include back-button.main;
  }
}

@mixin option-icon() {
  .kr-icon {
    min-width: $icon-wrapper-width;

    @include card-icons.brands($container-element: none);

    .kr-brand-icon {
      box-shadow: none;
      margin: 0;
    }
  }
}

@mixin option-label() {
  &__label label {
    @include fonts.default;

    span {
      @include fonts.default;
    }

    max-inline-size: calc(100% - $button-padding-x);
    transition: 0.3s color;
    max-height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: wrap;
  }
}
