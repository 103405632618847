@use "../../mixins/smartform/card-tooltip";
@use "./smartform/card-icons";
@use "./layer";
@use "./modal";
@use "./help-modal";

@mixin main() {
  .kr-layer-manager {
    @include card-icons.brands;
    @include card-tooltip.main($padding: 10px, $border-radius: 0);
    @include layer.main;
    @include modal.main;
    @include help-modal.main;
  }
}
