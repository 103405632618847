@import "initial";

.kr-embedded,
.kr-checkout,
.kr-smart-button,
.kr-discount-panel,
.kr-layer-manager {
  @include initial;
  @import "normalize";
}

/**
 * Selector must exclude .kr-smart-button and its subtree to avoid initial rule
 * to override smart-button styles.
 * Computed rules (.kr-smart-form *:not(...)) will have a higher weight
 * than .kr-smart-button alone.
 */
.kr-smart-form {
  @include initial;
}

@import "package.no-theme";
@import "package.classic";
