@use "./modal-button";

.kr-smart-form-wrapper.kr-type-popin {
  @include modal-button.smartform;
  @import "./modal";
  @import "./errors";
}

.kr-smart-form-wrapper.kr-type-embedded {
  @import "./modal";
}
