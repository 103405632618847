.kr-smart-form-wrapper {
  &.kr-type-embedded,
  &.kr-type-popin {
    .kr-smart-form-modal-wrapper .kr-smart-form-modal .kr-embedded.kr-extras-form-content {
      padding: 15px;
      width: 100%;

      .kr-payment-button,
      .kr-form-error {
        width: 100%;
      }
    }
  }

  &.kr-type-popin .kr-smart-form-modal-wrapper .kr-smart-form-modal .kr-embedded.kr-extras-form-content {
    padding-top: 0;
  }

  &.kr-type-embedded .kr-smart-form-modal-wrapper .kr-smart-form-modal .kr-embedded.kr-extras-form-content {
    width: 300px;
  }

  &.kr-type-popin {
    span.kr-extras-form-header {
      padding: 0 20px 20px;
    }
  }

  span.kr-extras-form-header {
    width: 300px;
    padding: 20px 20px 5px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    color: $color-primary;
  }
}
