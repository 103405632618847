@use "../variables" as *;
@use "./card-icons";
@use "../../../mixins/smartform/radio-button";
@include radio-button.keyframes;

@mixin main() {
  .kr-card-header {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    background-color: #f4f4f4;
    padding: 32px 14px 12px;
    margin-bottom: 14px;
    border-bottom: 1px solid $color-primary;
    border-left: 1px solid $smartform-border-color;
    border-right: 1px solid $smartform-border-color;
    border-top: 1px solid $smartform-border-color;

    &.kr-card-header--spbtn {
      border-bottom: 1px solid $smartform-border-color;
      cursor: pointer;

      &.kr-card-header--hover {
        border: 1px solid $color-primary;
      }

      &.kr-card-header--selected {
        background-color: #e5e9f6;
        border-color: $color-primary;
      }
    }

    span.kr-card-header-label {
      font-size: 12px;
      font-family: Roboto, sans-serif;
      position: absolute;
      top: 10px;
      left: 11px;
    }

    @include card-icons.main($padding-left: 0, $location: header);
    @include radio-button.main(
      $size: $smartform-radio-button-size,
      $border-color: #e3e3e3,
      $border-color-hover: $color-primary,
      $active-color: $color-primary
    );

    .kr-smart-form-radio-button {
      padding-right: 15px;
    }
  }
}
