@use "../classic-popin/header";

@mixin -header() {
  @include header.main;

  .kr-popin-modal-header {
    position: relative;
  }
}

@mixin -modal() {
  .kr-popin-redirection-modal {
    z-index: 1;
    box-shadow: 0 0 15px #121212;
  }
}

@mixin main() {
  .kr-popin-redirection {
    @include -header;
    @include -modal;
  }
}
