&[krvue] {
  .kr-pan,
  .kr-identity-document-number,
  .kr-card-holder-name,
  .kr-card-holder-mail,
  .kr-identity-document-type,
  .kr-installment-number,
  .kr-first-installment-delay {
    margin: 12px 0;
  }

  .kr-expiry,
  .kr-security-code {
    margin-left: 0;
    margin-top: 12px;
  }

  .kr-field.kr-text,
  .kr-field.kr-checkbox {
    margin: 12px $sides-margin 12px 0;
  }
}
