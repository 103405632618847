@use "./card-icons";
@use "../variables" as *;
@use "../fonts";

$spacing: 12px;
$h-gap: 10px;
$bg-color: #f9fafb;
$color-dark: #e5e7eb;

@mixin main() {
  .kr-partial-payment-panel {
    gap: $spacing;

    @include partial-payment-separator;

    .kr-partial-payments {
      gap: $spacing;

      @include partial-payment;
    }

    @include partial-payment-remaining;
  }
}

@mixin partial-payment() {
  .kr-partial-payment {
    height: 50px;
    border: 1px solid $color-dark;
    background: $bg-color;
    overflow: hidden;
    padding-right: 12px;
    gap: $h-gap;
    transition: 0.3s;

    &.test-enter-active,
    &.test-leave-active {
      transition: 0.3s;
    }

    &.test-enter,
    &.test-leave-to {
      opacity: 0;
    }

    .kr-partial-payment-opm-label {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    span {
      @include fonts.default;
    }

    @include card-icons.brands($container-element: none);

    .kr-brand-icon.kr-cards {
      margin-right: 0;
    }

    .kr-brand-icon.kr-cards {
      cursor: default;
    }

    .kr-partial-payment-amount {
      margin-left: auto;
      font-weight: fonts.$font-thick;
    }

    .kr-partial-payment-remove-icon-container {
      padding: 0 10px;
      width: 16px;
      background-color: $color-dark;
      transition: 0.3s;

      svg {
        stroke: #666;
        transition: 0.3s;
      }
    }

    // Hover
    &.kr-hover {
      border: 1px solid #b91c1c;
      background: #fef2f2;

      .kr-partial-payment-remove-icon-container {
        background-color: #b91c1c;

        svg {
          stroke: white;
        }
      }
    }
  }
}

@mixin partial-payment-remaining() {
  .kr-partial-payment-remaining {
    margin: 0 12px;

    span {
      @include fonts.md-thick;

      color: $color-secondary;
    }
  }
}

@mixin partial-payment-separator() {
  .kr-partial-payment-separator {
    .kr-partial-payment-line {
      height: 1px;
      background-color: $color-dark;
      flex: 1;
    }

    .kr-partial-payment-label {
      @include fonts.md-thick;

      line-height: 24px;
      color: $color-font;
    }
  }
}
