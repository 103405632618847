@mixin main() {
  /* Modal */
  .kr-help-button-wrapper .kr-help-modal-wrapper .kr-help-modal {
    background-color: #fff;
    box-shadow: 0 0 15px #121212;

    .kr-help-modal-header {
      background-color: #e4e4e4;
      border-bottom: 1px solid #808080;

      .kr-help-modal-close-button svg path {
        fill: #808080;
      }
    }

    .kr-help-modal-content {
      margin-top: 35px;
    }

    .kr-help-modal-content > div.kr-help-content {
      font-family: Roboto, sans-serif;
      font-size: 14px;
      color: #333;
    }

    .kr-help-modal-content > .kr-help-image,
    .kr-help-image-amex {
      margin-top: 25px;
    }
  }
}
