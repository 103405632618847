@use "sass:math";
@use "../classic/variables" as *;

@mixin legacy() {
  .kr-popin-modal-footer {
    .kr-whitelabel-logo img {
      margin-right: $sides-margin;
    }
  }

  &.kr-small-popin {
    padding-bottom: 30px;

    .kr-popin-modal-footer {
      height: 30px;
    }
  }
}

@mixin smartform() {
  .kr-popin-modal-footer {
    border: 5px solid #fff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: calc(100% - 10px);
    height: 60px;

    .kr-whitelabel-logo img.kr-logo-payzen {
      margin-right: 12px;
      height: 30px;
    }
  }
}
