$animation-name: "kr-layer-fade";

@mixin keyframes() {
  @keyframes #{$animation-name} {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}

@mixin main() {
  .kr-layer {
    &.kr-layer--opening {
      animation: 0.15s linear 0s 1 normal forwards #{$animation-name};
    }

    &.kr-layer--closing {
      animation: 0.15s linear 0s 1 reverse forwards #{$animation-name};
    }

    .kr-layer-message {
      font-family: Roboto, sans-serif;
      color: #fff;
      cursor: pointer;
    }
  }
}
