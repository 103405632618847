@use "../../mixins/flex" as *;
@use "../classic/variables" as *;

@mixin main() {
  .kr-popin-modal-header {
    height: 90px;

    @include flexbox;
    @include justify-content(center);
    @include align-items(center);

    border-bottom: 1px solid #808080;
    margin-bottom: $top-margin;

    &.kr-popin-header-layer {
      height: 0;
    }

    .kr-popin-modal-header-image {
      img.kr-header-logo {
        background-color: #000;
      }
    }

    i.fi-x-circle.icon-close-popup {
      width: 12px;
      height: 12px;
      top: 0;
      right: 0;
      margin: 7px 7px 0 0;
    }

    &.kr-secure-iframe-visible {
      height: 30px;
      border-bottom: 0;
      margin-bottom: 0;
      justify-content: space-between;

      i {
        &.fi-x-circle.icon-close-popup {
          margin-top: 0;
        }

        &.fi-lock {
          margin-left: 7px;
        }
      }
    }

    span.kr-popin-shop-name {
      margin: 40px 0 0;
      padding: 0 5px;
      text-align: center;

      span {
        font-family: Roboto, sans-serif;
        font-weight: bold;
        line-height: 19px;
      }
    }
  }
}

@mixin smartform() {
  .kr-popin-modal-header.kr-smart-form-modal-header {
    box-sizing: border-box;
    border: $smartform-popin-border solid #f4f4f4;
    border-top-left-radius: $smartform-popin-border-radius;
    border-top-right-radius: $smartform-popin-border-radius;
    height: 100px;

    // Close button
    i.fi-x-circle.icon-close-popup {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 18px;
      top: 0;
      right: 0;
      margin: 5px 5px 0 0;
    }

    &.kr-secure-iframe-visible i.fi-x-circle.icon-close-popup {
      margin: 0;
    }

    // Shop name
    .kr-popin-shop-name {
      left: 10px;
      top: 3px;
      padding: 3px 0 7px;
      border-bottom: 1px solid $color-primary;

      span {
        font-family: Roboto, sans-serif;
        font-size: 17px;
        color: #4a4a4a;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: $shop-name-width;
        display: block;
      }
    }

    // Shop logo
    .kr-popin-modal-header-image {
      bottom: 8px;

      .kr-header-border {
        width: 34px;
        height: 34px;
        background-color: $color-primary;
      }

      .kr-header-logo {
        width: 32px;
        height: 32px;
      }
    }

    // Amount + orderID
    span.kr-popin-amount,
    span.kr-popin-order-id {
      right: unset;
      left: 60px;
      display: block;

      span {
        font-family: Roboto, sans-serif;
      }
    }

    span.kr-popin-order-id {
      bottom: 8px;
    }

    span.kr-popin-amount {
      bottom: 25px;

      span {
        color: $color-primary;
        font-size: 18px;
      }
    }

    .kr-popin-total-amount {
      bottom: 9px;

      .kr-total-amount-label {
        font-family: Roboto, sans-serif;
        font-size: 11px;
        font-weight: 500;
        color: #444;
        margin-bottom: 3px;
      }

      .kr-total-amount-value {
        font-family: Roboto, sans-serif;
        font-size: 18px;
        font-weight: 400;
      }
    }

    // Iframe open
    &.kr-secure-iframe-visible {
      position: relative;
      height: 30px;
      margin-bottom: 0;
      background-color: #f5f5f5;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 4px;
      border-bottom: 1px solid #ccc;

      .kr-popin-header {
        font-family: Roboto, sans-serif;
        color: #666;
        line-height: unset;
      }

      .fi-lock {
        box-sizing: border-box;
        order: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
