@use "../../variables" as *;
@use "../split-payment";
@use "./header";
@use "./wallet";
@use "./cards";
@use "./opm";
@use "./modal";
@use "./extras-form";
@use "../../../classic-popin/smartform/modal-button";

@mixin main() {
  gap: $card-elements-gap;

  .kr-sf-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: $smartform-methods-gap;

    &--cards-expanded {
      gap: $card-elements-gap;
    }

    @include split-payment.grid-section;
    @include header.section;
    @include wallet.section;
    @include cards.section;
    @include opm.section;
    @include modal.wrapper;
    @include extras-form.wrapper;
    @include modal-button.smartform;
  }

  @include single-payment-button;
}

@mixin single-payment-button() {
  .kr-smart-form-single-payment-button {
    margin: 0;
  }
}

@mixin smart-button() {
  .kr-smart-button-wrapper {
    margin: 0;
  }
}
