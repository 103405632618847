@use "./../../no-theme/smartform/loading-animation";
@use "./card-icons";
@use "../../../mixins/smartform/radio-button";
@include radio-button.keyframes;

.kr-smart-button-wrapper {
  margin: 10px 0;

  @import "package/classic/error";

  .kr-form-error {
    margin: 5px 0 0;

    span {
      font-size: 13px;
    }
  }

  .kr-skeleton-wrapper {
    .kr-skeleton {
      display: flex;
    }
  }
}

/* KJS-2563: Must be outside to allow the styling with just .kr-smart-button selector */
.kr-smart-button {
  width: $smartbutton-width;
  height: 60px;

  &--spbtn {
    width: $smartbutton-spb-width;
  }

  &--fallback {
    width: 100%;
  }
}

.kr-sm-internal {
  $line-height: 20px;

  align-items: center;
  position: relative;
  box-sizing: border-box;
  padding: 0 20px;
  border: 1px solid #b7b7b7;
  box-shadow: rgb(149, 157, 165, 15%) 0 0 24px;
  background-color: #fff;

  @include radio-button.main(
    $size: $smartform-radio-button-size,
    $border-color: #e3e3e3,
    $border-color-hover: $color-primary,
    $active-color: $color-primary
  );

  .kr-smart-form-radio-button {
    padding-right: 15px;
  }

  $exclude-list: ".kr-loading", ".kr-locked";

  &:hover:not(#{$exclude-list}),
  &:focus:not(#{$exclude-list}) {
    border: 1px solid $color-primary;
    background-color: #fff;
    outline: none;
  }

  .kr-method-info {
    margin: 1px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 24px;
    cursor: pointer;

    > .kr-method-icon,
    > .kr-card-icons.kr-single-icon {
      width: 30px;
      margin-right: 15px;
    }

    // Icon
    .kr-method-icon {
      display: flex;
      cursor: pointer;

      svg {
        fill: $color-primary;
      }

      svg,
      img {
        width: 30px;
        height: 24px;
      }
    }

    label.kr-amount-label {
      font-family: Roboto, sans-serif;
      color: #444;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    // Label
    .kr-label {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      width: 85%;
      overflow: hidden;
      height: $line-height;

      label.kr-method-label {
        font-family: Roboto, sans-serif;
        text-transform: none;
        color: #666;
        line-height: $line-height;
        vertical-align: middle;
        font-size: 13px;
      }
    }
  }

  // Card icons
  &.kr-cards {
    @include card-icons.main;

    .kr-method-info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  // Loading animation
  .kr-loading-animation {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    @include loading-animation.main($color-primary);
  }
}
