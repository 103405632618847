@use "../../../mixins/smartform/split-payment/label";
@use "../../../mixins/smartform/split-payment/panel";
@use "../../../mixins/smartform/split-payment/separator";
@use "../../../mixins/smartform/split-payment/timeline";
@use "../variables" as *;
@use "./grid/section";
@use "./methods-list/common";

@mixin root() {
  .kr-sp-panel-wrapper,
  .kr-payment-schedule {
    @include panel.main($font-family: $font-family);
    @include timeline.main($font-family: $font-family);
    @include separator.main($font-family: $font-family);
  }
}

@mixin smartform() {
  .kr-sp-panel-wrapper {
    --kr-sp-seperator-padding-y: calc(#{$smartform-section-margin} + 8px);

    @include separator.smartform;
  }

  @include -legacy-smartform;

  .kr-popin-modal-header {
    @include label.header($font-family: $font-family) {
      position: absolute;
      right: 10px;
      bottom: 25px;
      left: 60px;
      z-index: 1;
    }
  }
}

@mixin -legacy-smartform() {
  .kr-smart-form-methods .kr-sp-panel-wrapper {
    display: block;

    &__label {
      display: block;

      @include common.font;

      padding: $smartform-section-margin 0;
    }
  }
}

@mixin grid-section() {
  .kr-sp-panel-wrapper {
    display: flex;
    flex-direction: column;
    padding-top: $smartform-section-margin;

    @include section.spacing(
      $gap: $smartform-methods-gap
    );
    @include section.label;

    .kr-sp-panel-separator {
      // Cancel out gap applied to .kr-sf-wrapper--cards-expanded
      margin-bottom: -$card-elements-gap;

      // Cancel out gap wrapper section gap
      margin-top: -$smartform-section-margin;
    }
  }
}

@mixin button-label() {
  @include label.button($font-family: $font-family) {
    width: 100%;

    .kr-sp-button-amount,
    .kr-sp-button-total {
      width: unset;
    }
  }
}
