@use "../variables" as *;

@mixin popin() {
  .kr-wallet-card-list {
    margin: 0;

    .kr-card-list-wrapper {
      border: 0;

      .kr-card-element .kr-card-label {
        margin-left: 24px;
      }
    }
  }
}

@mixin embedded() {
  .kr-wallet-card-list {
    margin: 16px 0;

    .kr-card-list-wrapper {
      border: 1px solid $color-grey-border;
      margin-bottom: 4px;

      .kr-card-element,
      .kr-card-element .kr-delete-button,
      .kr-card-element .kr-delete-button svg,
      .kr-card-element .kr-card-label,
      .kr-card-element .kr-card-label label {
        cursor: pointer;
      }

      .kr-card-element {
        &:not(.kr-deleted) {
          padding: 12px 0;
        }

        &.kr-deleted {
          transition: all 0.5s;
          height: 0;
          padding: 0;
          opacity: 0;
        }

        .kr-brand-icon svg {
          width: 24px;
          height: 16px;
        }

        .kr-brand-icon.kr-loading-assets svg {
          padding: 0;
        }

        i.fi-x-circle svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .kr-wallet-card-list .kr-card-list-wrapper .kr-card-element .kr-card-label {
    margin-left: 31px;

    input[type="radio"] {
      display: none;
    }

    label {
      position: relative;
      min-width: 150px;

      span {
        font-family: Roboto, sans-serif;
        font-size: 14px;
        color: $color-grey-font;
      }

      span::before,
      span::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
      }

      span:hover {
        cursor: pointer;
      }

      span::before {
        left: -21.5px;
        width: 10px;
        height: 10px;
        background-color: $color-light;
        border-radius: 50%;
        border: 1px solid $color-grey-font;
      }

      span::after {
        left: -18.5px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: $color-light;
      }
    }

    input[type="radio"]:checked + label span::after {
      background-color: $color-grey-font;
    }
  }
}
