@use "sass:math";
@use "./variables" as *;
@use "./../no-theme/smartform/loading-animation";

@mixin main() {
  $gap: 30px;
  $spinner-size: 20px;

  .kr-loading-overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .kr-overlay-logo {
      margin-bottom: $gap;
    }

    .kr-overlay-message {
      font-family: Roboto, sans-serif;
      font-weight: 500;
      color: $color-light;
      top: unset;
      bottom: unset;
    }

    .kr-loading-animation {
      margin-top: $gap;
      margin-bottom: 40px;
      padding: math.div($spinner-size, 2);
      cursor: pointer;

      @include loading-animation.overlay($color: $color-light, $spinner-size: $spinner-size);
    }
  }
}
